/********
 * Front-end CSS file
 ********/

@import "components/fonts.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
  background-color: white;
}

a, p, h2, h3, h4, strong, li {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;
}

@media (min-width: 1280px) {
  .second-level-nav-item .second-level-nav-dd { display: none; }
  .second-level-nav-item:hover .second-level-nav-dd,
  .second-level-nav-item:focus .second-level-nav-dd,
  .second-level-nav-item:focus-within .second-level-nav-dd {
    display: flex;
  }
}

.delay {
  visibility: hidden;
 }

 .delay.loaded {
  visibility: visible;
 }

@layer components {

  .button {
    @apply inline-flex px-6 pt-3.5 pb-2.5 md:px-8 font-sans uppercase no-underline transition-colors duration-500 tracking-wider items-center font-bold self-center justify-center lg:min-w-[100px];
  }

  .nav-link {
    @apply font-sans inline-flex px-4 md:px-8 py-4 uppercase no-underline antialiased tracking-wider leading-none justify-center text-white font-bold;
  }

  .button-primary {
    @apply button bg-primary/50 backdrop-blur-sm text-white transition duration-700 hover:bg-primary/100;
  }

  .button-secondary {
    @apply button border-primary bg-black/50 backdrop-blur-sm hover:bg-black/100 text-white transition-all duration-500;
  }

  .front-h1 {
    @apply text-2xl md:text-6xl font-heading subpixel-antialiased;
  }

  .front-h2 {
    @apply text-3xl md:text-4xl pt-4 font-bold;
  }

  .front-h3 {
    @apply uppercase text-lg md:text-xl text-center tracking-wide
  }

  .page-h1 {
    @apply text-4xl font-bold text-black/90 font-sans;
  }

  .page-h2 {
    @apply text-4xl md:text-5xl pt-4 font-heading text-primary md:mt-16 antialiased border-b-2 border-accent;
  }

  .page-h3 {
    @apply uppercase text-lg md:text-xl text-center tracking-wide
  }

  .block-h2 {
    @apply page-h2;
  }

  .block-h3 {
    @apply page-h3;
  }

  .block-btn-light { /** light mode **/
    @apply button text-xl text-white bg-black/40 border-primary-dark hover:border-primary hover:bg-primary hover:text-white;
  }

  .contact-heading {
    @apply font-heading text-white/80 pb-1 text-2xl lg:text-2xl;
  }

  .contact-mono {
    @apply text-white/100 cursor-pointer capitalize font-mono hover:underline hover:underline-offset-2 tracking-tight text-base pb-2
  }

  .animate-spin {
    animation: spin 9000ms linear infinite !important;
  }

  .form-button {
    @apply pt-3 pb-2 px-4 font-mono antialiased border border-accent hover:bg-primary hover:text-white w-full md:w-auto tracking-wide;
  }
}