/* shadows-into-light-regular - latin */
@font-face {
  font-family: 'Shadows Into Light';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/fonts/shadows/shadows-into-light-v15-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/fonts/shadows/shadows-into-light-v15-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/shadows/shadows-into-light-v15-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/shadows/shadows-into-light-v15-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('/fonts/shadows/shadows-into-light-v15-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/fonts/shadows/shadows-into-light-v15-latin-regular.svg#ShadowsIntoLight') format('svg'); /* Legacy iOS */
}

/* overpass-mono-regular - latin */
@font-face {
  font-family: 'Overpass Mono';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/fonts/overpass/overpass-mono-v14-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/fonts/overpass/overpass-mono-v14-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/overpass/overpass-mono-v14-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/overpass/overpass-mono-v14-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('/fonts/overpass/overpass-mono-v14-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/fonts/overpass/overpass-mono-v14-latin-regular.svg#OverpassMono') format('svg'); /* Legacy iOS */
}

/* overpass-regular - latin */
@font-face {
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/fonts/overpass/overpass-v12-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/fonts/overpass/overpass-v12-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/overpass/overpass-v12-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/overpass/overpass-v12-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('/fonts/overpass/overpass-v12-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/fonts/overpass/overpass-v12-latin-regular.svg#Overpass') format('svg'); /* Legacy iOS */
}

/* overpass-700 - latin */
@font-face {
  font-family: 'Overpass';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('/fonts/overpass/overpass-v11-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/fonts/overpass/overpass-v11-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/overpass/overpass-v11-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/overpass/overpass-v11-latin-700.woff') format('woff'), /* Modern Browsers */
       url('/fonts/overpass/overpass-v11-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/fonts/overpass/overpass-v11-latin-700.svg#Overpass') format('svg'); /* Legacy iOS */
}